import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useStore } from 'react-hookstore';
import { withTranslation } from 'react-i18next'
import { Redirect } from "react-router-dom"
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
//import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden'
import Tooltip from '@material-ui/core/Tooltip';

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import Marquee from 'react-double-marquee';
import Divider from '@material-ui/core/Divider'

// Sustituir BotonCalendario por Badge+Calendario para que no de mensaje de error.
//import BotonCalendario from './BotonCalendario.js';
import Badge from '@material-ui/core/Badge';
import Calendario from '@material-ui/icons/DateRange'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import BotonSinSalto from './../../components/botones/BotonSinSalto.js'
import BotonValidacion from './../../components/botones/BotonValidacion.js'
//import RadioEntregasMercado from './../../components/radioButtons/RadioBotonesMercado.js'
//import SelectorCantidad from './../../components/capturas/SelectorCantidad.js'
import SelectorDias from './../../components/capturas/SelectorDias.js'
import { DateUtils } from 'react-day-picker';

import ThemeGeroa from '../ThemeGeroa.js'
import config from './../../config/config.js'
import { FiltraFechasEntrega } from './../../config/funcionesParticulares.js'
//import { BuscaIndice } from '../../config/funcionesPropias.js';


const useStyles = makeStyles(theme => ({
    botonMercado: {
        width: '100%', // Obligatorio: Todos los botones del mismo tamaño
        textTransform: "none",
        //textAlign: 'center',
        border: '1px solid #487e4c',
        borderRadius: '8px',
        color: 'FFF',
        backgroundColor: '#FAFAFA',
        fontWeight: '600',
        outline: 'none',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        transition: 'all 0.5s',
        cursor: 'pointer',
        margin: '5px',
        "&:hover": {
            boxShadow: "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        "&:active": {
            transform: 'translateY(8px)'
        },
        "&:disabled": {
            backgroundColor: 'lightGray', //"#121212",
        },
    },
    boxMercado: {
        width: '100%', // Obligatorio: Todos los botones del mismo tamaño
        textTransform: "none",
        //textAlign: 'center',
        border: '1px solid #487e4c',
        borderRadius: '8px',
        color: 'FFF',
        backgroundColor: '#FAFAFA',
        fontWeight: '600',
        outline: 'none',
        boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        transition: 'all 0.5s',
        cursor: 'pointer',
        margin: '5px 5px -7px 5px', // superior derecha inferior izquierda
        padding: '7px',
        "&:hover": {
            boxShadow: "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)",
            transform: 'translateY(5px)',
            backgroundColor: "rgba(153, 153, 153, 0.2)"
        },
    },
    contenedor: {
        //border: '1px solid blue',
        display: 'flex',
        flexDirection: 'row',
        //padding: 5,
    },
    izquierda: {
        //border: '1px solid blue',
        width: '75%',
        [theme.breakpoints.down("xs")]: { width: "100%" }, // 414
        display: 'flex',
        flexDirection: 'row',
        // Si flexDirection es 'row' => justifyContent: 'flex-start' 'center' 'flex-end'
        // 'space-between' 'space-around
        justifyContent: 'flex-start', // space-around
    },
    derecha: {
        //border: '1px solid red',
        width: '25%',
        [theme.breakpoints.down("xs")]: { width: '0%' }, // 414
        flexDirection: 'column',
        // Si flexDirection='column' => alignItems: 'flex-start' 'flex-end' 'center' 'stretch'
        alignItems: 'center', //
    },
    fotoMercado: {
        //border: '1px solid #487e4c',
        width: '100px',
        //width: '18%',
        display: 'flex',
        flexDirection: 'column',
        //alignContent: 'right', // NO FUNCIONA en la clase
    },
    datosMercado: {
        //border: '1px solid #487e4c',
        width: '100%',
        [theme.breakpoints.up("xs")]: { width: '62%' }, // 414 62
        [theme.breakpoints.up("sm")]: { width: '72%' }, // 600 72
        [theme.breakpoints.up("md")]: { width: '84%' }, // 960 83
        //[theme.breakpoints.up("lg")]: {width: '83%'}, // 1280
        //[theme.breakpoints.up("xl")]: {width: '83%'}, // 1920
        //flexDirection: 'column',
    },
    fotoProductor: {
        //border: '1px solid red',
        flexDirection: 'row',
        // Si flexDirection es 'row' => justifyContent: 'flex-start' 'center' 'flex-end'
        // 'space-between' 'space-around
        justifyContent: 'space-around',
    },
    textoProductor: {
        color: ThemeGeroa.palette.secondary.main,
        /////border: '1px solid green',
        fontSize: 14,
        fontWeight: 600,
        width: '84px',
        height: '84px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
    },
    flechasFoto: {
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    marquesina: {
        textSize: '48px',
        fontWeigh: '700',
        color: '#d32f2f'
    },
}));

const BotonMercado = ({ t, ...props }) => {
    const classes = useStyles();
    const [valuesGlobales /*, setValuesGlobales*/] = useStore('DatosGlobales');
    const [parametros, setParametros] = useState({
        // Parametros que no vienen del fuente que llama al boton
        seSalta: false
    })
    const [numFoto, setNumFoto] = useState('');
    const [inicioF, setInicioF] = useState(true);
    const [masInfo, /*setMasInfo*/] = useState(false);

    const idioma = valuesGlobales.idioma;
    const { mercado } = props; // equivale a: const mercado = props.mercado;


    let feM = FiltraFechasEntrega(mercado.calendario, 0)
        .map((f, i) => {
            return { //elemento: FechaLargaEsp(new Date(f), valuesGlobales.idioma),
                seleccion: i === 0 ? true : false,
                fecha: new Date(f)
            }
        })

    const [fechasEntregaMercado, /*setFechasEntregaMercado*/] = useState(feM)
    const [openDialoFe, setOpenDialoFe] = useState(false)
    const [fechasPedidos, setFechasPedidos] = useState([])
    //
    /* Ya no hace falta esto
    mercado.puntosDeRecogida.forEach(p => {
        let tp = p.puntoRecogida.indexOf("+")
        let tp1
        if (tp !== -1) {
            tp1 = p.puntoRecogida.substr(0, tp)
            p.puntoRecogida = tp1
        }
    })
    */
    //
    const puntos = mercado.puntosDeRecogida.map(
        punto => punto.puntoRecogida + " " + punto.detalle + " (" + punto.localidad.nombre + ")"
    ).join("; ")


    const coordinador = mercado.coordinadores.filter(coo => coo.defecto)[0];

    // No se usa fechas. comentado.
    //const fechas = (idioma === "Es") ? mercado.textoCalendarioEs : mercado.textoCalendarioEu

    const textoEspecial = (idioma === "Es") ? (mercado?.textoEspecialEs || "") : (mercado?.textoEspecialEu || "")
    const textoAmpliado = (idioma === "Es") ? (mercado?.textoAmpliadoEs || "") : (mercado?.textoAmpliadoEu || "")

    // Referencia que indica que el componente está montado. //
    const mountedRef = useRef(true)




    const handleDialoFe = () => {
        setOpenDialoFe(false);
    };

    const handleOpenDialoFe = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenDialoFe(true);
    };


    const handleSelectorDia = (dia) => {
        let repe = [...fechasPedidos]
        // Localizar el día en FechasEntregaMercado. //
        let index = repe.findIndex(f => DateUtils.isSameDay(f.fecha, dia))
        if (index !== -1) {
            // Cambiar seleccion. //
            //let sel = fechasPedidos[index].seleccion
            //fechasPedidos[index].seleccion = !sel
            // Eliminar del Array. //
            repe.splice(index, 1);
        } else {
            // Buscar indice en fEM. //
            index = fechasEntregaMercado.findIndex(f => DateUtils.isSameDay(f.fecha, dia))
            // Hacer copia del Objeto. //
            let tp = { ...fechasEntregaMercado[index] }
            tp.seleccion = true
            repe.push(tp)
        }

        // Si el mercado no tiene fiechas de Entrega. //
        // Esto no hace falta si no dejamos abrir el calendario, ni seleccionar días. //
        if (fechasEntregaMercado.length === 0) {
            ////////////////////////////////////////////////////////////////////////////////////////////////////////setDialogoError({ hayError: true, tipo: 5 })
        } else {
            // Ordenar el array. //
            setFechasPedidos(repe.sort((p1, p2) => {
                let ret = 0
                if (p1.fecha > p2.fecha) { ret = 1 }
                if (p1.fecha < p2.fecha) { ret = -1 }
                return ret
            }))
        }
    }


    async function onClickBotonValidacion(event) {
        event.stopPropagation();
        event.preventDefault();
        // Si NO esta desactivado. //
        if (!props.desactivado) {
            //Casca
            //setValuesGlobales({ ...valuesGlobales, intentoMercado: props.mercadoNumero});
            valuesGlobales.intentoMercado = mercado.name
            //let retorno = await props.validacion(event)
            let retorno = await props.validacion(mercado.name)
            // Si esta montado, actualiza estado. //
            if (mountedRef.current) {
                setParametros({ ...parametros, seSalta: retorno })
            }
        }
    }

    function botonFoto(event, nMov) {
        event.stopPropagation();
        event.preventDefault();
        // Quitar Texto
        if (inicioF) setInicioF(false);
        // Buscar Imagen
        let total = fotosProductores.length - 1;
        let index = numFoto + nMov;
        index = (index < 0) ? total : index;
        index = (index > total) ? index = 0 : index;
        setNumFoto(index);
    }

    /* // No se usa masInformacion. comentado
    const masInformacion = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setMasInfo(!masInfo)
    }
    */


    function abreCorreo(event, email) {
        event.stopPropagation();
        event.preventDefault();
        // Abrir el programa de correo electrónico. //
        window.location = "mailto:" + email
    }


    //fotosProductores.push(config.misImagenes+'/mercados/log'+mercado.name+'.jpg')

    let fotosProductores = mercado.productores.map((codProductor) => config.misImagenes + '/shops/' + codProductor + '_img.jpg')


    //src={config.misImagenes+'/mercados/log'+mercado.name+'.jpg'}

    // foto inicial aleatoria
    let nFoto = Math.round(Math.random() * (fotosProductores.length - 1));
    if (numFoto === '') setNumFoto(nFoto);


    async function validaAlta(event) {
        event.stopPropagation();
        event.preventDefault();
        // Si no esta montado, devuelve false
        if (!mountedRef.current) return false
        valuesGlobales.usuarioAltaMercado = { merCod: mercado.name, merNom: mercado.description, cooMail: coordinador?.email }
        return true
    }


    function verBotonSolicitud(m) {
        let retorno = false
        //
        // Si el marcado es abierto no presentar nunca el boton
        if (!m.abierto) {
            // Si el usuario está sin registar
            if (valuesGlobales.emailUsuario === "") {
                retorno = true
            } else {
                if (!valuesGlobales.mercadosUsuario.includes(m.name)) {
                    retorno = true
                }
            }
        }
        return retorno
    }

    // Cuando el componente se desmonta, cambiamos la referencia. //
    useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    return (
        <MuiThemeProvider theme={ThemeGeroa}>
            <Box
                //className={classes.botonMercado}
                className={classes.boxMercado}
                onClick={onClickBotonValidacion}
                // No hace caso a esto (No es un Botón) //
                //disabled={props.desactivado}
                style={{ backgroundColor: props.desactivado ? 'lightGray' : '#FAFAFA' }}
            >
                <Grid container className={classes.contenedor}>
                    {mercado.especial ?
                        <Grid container className={classes.marquesina}>
                            <Grid item
                                style={{
                                    width: '95%',
                                    whiteSpace: 'nowrap',
                                    marginLeft: '5px',
                                    fontSize: '26px',
                                    fontWeight: '600'
                                }}
                            >
                                <Marquee direction={'left'} childMargin={140} speed={'0.15'} delay={0}>
                                    {textoEspecial}
                                </Marquee>
                            </Grid>
                        </Grid>
                        :
                        <Fragment />
                    }
                    <Grid container className={classes.izquierda}>
                        <Grid container>
                            <Grid item align={"left"} style={{ minWidth: "60%", marginLeft: 10 }}>
                                {mercado.abierto ?
                                    <Tooltip title={t("Mercados.TextoAbierto")}>
                                        {/*<LockOpenIcon style={{ marginTop: 2, color: 'green', fontSize: 16 }} />*/}
                                        <Fragment />
                                    </Tooltip>
                                    :
                                    <Tooltip title={t("Mercados.TextoCerrado1")}>
                                        {verBotonSolicitud(mercado) ?
                                            <LockIcon style={{ marginTop: 2, color: 'red', fontSize: 16 }} />
                                            :
                                            <LockOpenIcon style={{ marginTop: 2, color: 'green', fontSize: 16 }} />
                                        }
                                    </Tooltip>
                                }
                                <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 15, fontWeight: 500, marginLeft: 10 }} display='inline' >
                                    {mercado.description}
                                </Typography>
                            </Grid>
                            {!verBotonSolicitud(mercado) &&
                                <Grid item align={"right"} style={{ minWidth: "20%", marginLeft: 10 }}>
                                    <BotonSinSalto
                                        texto={t('Mercados.HacerPedido')}
                                    // Esto sobra, lo hace el boton principal. //
                                    //accion={onClickBotonValidacion}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid container>
                            <Grid item align={"left"} style={{ width: "100%" }}>
                                <Fragment>
                                    <Typography //display="inline"
                                        style={{
                                            color: ThemeGeroa.palette.primary.main,
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginTop: 0,
                                            marginLeft: 0
                                        }}>
                                        {t("Mercados.Productos") + ":"}
                                    </Typography>
                                    <Typography //display="inline"
                                        style={{
                                            color: ThemeGeroa.palette.gris.main,
                                            fontSize: 10,
                                            fontWeight: 500,
                                            marginLeft: 0
                                        }}>
                                        {mercado.familias.map(f => f).join("// ")}
                                    </Typography>
                                </Fragment>

                                {(idioma === "Es" ? mercado.textoPuntosEs : mercado.textoPuntosEu) !== "" ?
                                    <Fragment>
                                        <Typography //display="inline"
                                            style={{
                                                color: ThemeGeroa.palette.primary.main,
                                                fontSize: 14,
                                                fontWeight: 500,
                                                marginTop: 0,
                                                marginLeft: 0
                                            }}>
                                            {t("Mercados.Puntos") + ":"}
                                        </Typography>
                                        <Typography //display="inline"
                                            style={{
                                                color: ThemeGeroa.palette.gris.main,
                                                fontSize: 10,
                                                fontWeight: 500,
                                                marginLeft: 0
                                            }}>
                                            {idioma === "Es" ? mercado.textoPuntosEs : mercado.textoPuntosEu}
                                        </Typography>
                                    </Fragment>
                                    :
                                    (puntos !== '' ?
                                        <Fragment>
                                            <Typography //display="inline"
                                                style={{
                                                    color: ThemeGeroa.palette.primary.main,
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    marginTop: 0,
                                                    marginLeft: 0
                                                }}>
                                                {t("Mercados.Puntos") + ":"}
                                            </Typography>
                                            <Typography //display="inline"
                                                style={{
                                                    color: ThemeGeroa.palette.gris.main,
                                                    fontSize: 10,
                                                    fontWeight: 500,
                                                    marginLeft: 0
                                                }}>
                                                {puntos}
                                            </Typography>
                                        </Fragment>
                                        :
                                        <Fragment />
                                    )
                                }

                                {(idioma === "Es" ? mercado.textoLocalidadesEs : mercado.textoLocalidadesEu) !== "" ?
                                    <Fragment>
                                        <Typography //display="inline"
                                            style={{
                                                color: ThemeGeroa.palette.primary.main,
                                                fontSize: 14,
                                                fontWeight: 500,
                                                marginTop: 0,
                                                marginLeft: 0
                                            }}>
                                            {t("Mercados.Entrega") + ":"}
                                        </Typography>
                                        <Typography //display="inline"
                                            style={{
                                                color: ThemeGeroa.palette.gris.main,
                                                fontSize: 10,
                                                fontWeight: 500,
                                                marginLeft: 0
                                            }}>
                                            {idioma === "Es" ? mercado.textoLocalidadesEs : mercado.textoLocalidadesEu}
                                        </Typography>
                                    </Fragment>
                                    :

                                    (mercado.localidades.length !== 0 ?
                                        <Fragment>
                                            <Typography //display="inline"
                                                style={{
                                                    color: ThemeGeroa.palette.primary.main,
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    marginTop: 0,
                                                    marginLeft: 0
                                                }}>
                                                {t("Mercados.Entrega") + ":"}
                                            </Typography>
                                            <Typography //display="inline"
                                                style={{
                                                    color: ThemeGeroa.palette.gris.main,
                                                    fontSize: 10,
                                                    fontWeight: 500,
                                                    marginLeft: 0
                                                }}>
                                                {mercado.localidades.map(loc => loc.nombre).join(", ")}
                                            </Typography>
                                        </Fragment>
                                        :
                                        <Fragment />
                                    )
                                }

                                < Typography display='inline'
                                    style={{
                                        color: ThemeGeroa.palette.primary.main,
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginLeft: 0
                                    }}>
                                    {t("Mercados.Fechas") + ": "}
                                </Typography>
                                {/*
                                <BotonCalendario
                                    style={{ color: ThemeGeroa.palette.primary.main, marginTop: -10, marginLeft: -5 }}
                                    onClick={handleOpenDialoFe}
                                />
                                */}
                                <Badge color="secondary" overlap="circular">
                                    <Calendario
                                        style={{ color: ThemeGeroa.palette.primary.main, marginTop: -5, marginRight: 10 }}
                                        fontSize="large"
                                        onClick={handleOpenDialoFe}
                                    />
                                </Badge>
                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={event => event.stopPropagation()}
                                    onFocus={event => event.stopPropagation()}
                                    control={
                                        <Dialog
                                            // si no hay fechas no dejar abrir el calendario. //
                                            open={openDialoFe && fechasEntregaMercado.length > 0}
                                            onClose={handleDialoFe}
                                        >
                                            <DialogContent>
                                                <SelectorDias
                                                    fechas={fechasEntregaMercado}
                                                    repite={fechasPedidos}
                                                    idioma={valuesGlobales.idioma === 'Es' ? 'es' : 'eu'}
                                                    accion={handleSelectorDia}
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <BotonSinSalto
                                                    texto={"Aceptar"}
                                                    accion={handleDialoFe}
                                                />
                                            </DialogActions>
                                        </Dialog>
                                    }
                                />
                                <br />
                                <Fragment>
                                    {verBotonSolicitud(mercado) &&
                                        <Typography
                                            style={{
                                                color: ThemeGeroa.palette.naranja.main,
                                                fontSize: 12,
                                                fontWeight: 500,
                                                marginTop: 0,
                                                marginLeft: 10
                                            }}>
                                            {t("Mercados.TextoCerrado2")}
                                        </Typography>
                                    }
                                    <Typography display='inline'
                                        style={{
                                            color: ThemeGeroa.palette.primary.main,
                                            fontSize: 14,
                                            fontWeight: 500,
                                            marginTop: 0, // 5
                                            marginLeft: 0
                                        }}>
                                        {t("Mercados.Coordinador") + ": "}
                                    </Typography>
                                    {verBotonSolicitud(mercado) ?
                                        <Box display="inline">
                                            <BotonValidacion
                                                texto={t('Mercados.Solicitud de alta')}
                                                salto='/Usuarios'
                                                validacion={validaAlta}
                                                style={{
                                                    marginLeft: 5, minWidth: idioma === 'Es' ? 120 : 100,
                                                    backgroundColor: ThemeGeroa.palette.naranja.main,
                                                }}
                                            >
                                            </BotonValidacion>
                                        </Box>
                                        :
                                        <></>
                                    }
                                    <br />
                                    <Typography display='inline'
                                        onClick={e => abreCorreo(e, coordinador?.email)}
                                        style={{
                                            color: ThemeGeroa.palette.gris.main,
                                            fontSize: 10,
                                            fontWeight: 500,
                                            marginLeft: 0,
                                        }}>
                                        {coordinador?.email}
                                    </Typography>
                                    <Typography display='inline'
                                        style={{
                                            color: ThemeGeroa.palette.gris.main,
                                            fontSize: 10,
                                            fontWeight: 500,
                                            marginLeft: 5,
                                        }}>
                                        {coordinador?.nombre + " " +
                                            coordinador?.telefono + " "}
                                    </Typography>
                                </Fragment>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Hidden xsDown>
                        <Grid container className={classes.derecha}>
                            <Grid item>
                                <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 14, fontWeight: 600, marginBottom: 10 }}>
                                    {/*fotosProductores.length + " " + t("Mercados.Productor" + (fotosProductores.length > 1 ? "es" : "")) */}
                                    {t("Mercados.Productores")}
                                </Typography>
                            </Grid>
                            <Grid container className={classes.fotoProductor}>
                                <Grid item>
                                    <ArrowLeft style={{
                                        marginTop: 30,
                                        color: 'white',
                                        backgroundColor: ThemeGeroa.palette.primary.main,
                                        width: 25,
                                        height: 25,
                                        borderRadius: 50,
                                        //fontSize: 10
                                    }}
                                        onClick={e => botonFoto(e, -1)} />
                                </Grid>
                                <Grid item>
                                    {inicioF ?
                                        <Grid>
                                            <img key={mercado.name}
                                                alt={mercado.description}
                                                //width="100%"
                                                width='85px' height='85px'
                                                style={{}}
                                                //src={config.misImagenes + '/mercados/mer' + mercado.name + '.jpg'}
                                                src={config.misImagenes + '/mercados/log' + mercado.name + '.jpg'}
                                            />
                                        </Grid>
                                        :
                                        <img key={mercado.name}
                                            alt={mercado.description + " " + numFoto}
                                            //width="100%"
                                            width='85px' height='85px'
                                            style={{  //marginRight: 15,
                                                //border: '1px solid #487e4c',
                                            }}
                                            src={fotosProductores[numFoto]}
                                        //onError={(event) => event.target.setAttribute("src", defaultImgProductor)}
                                        />
                                    }
                                </Grid>
                                <Grid item>
                                    <ArrowRight style={{
                                        marginTop: 30,
                                        //marginLeft: 4,
                                        color: 'white',
                                        backgroundColor: ThemeGeroa.palette.primary.main,
                                        width: 25,
                                        height: 25,
                                        borderRadius: 50,
                                        //fontSize: 10
                                    }}
                                        onClick={e => botonFoto(e, 1)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                    {masInfo ?
                        <Grid container>
                            <Grid item align="left">
                                <Divider />
                                <Typography style={{ color: ThemeGeroa.palette.primary.main, fontSize: 14, fontWeight: 500, marginTop: 0, marginLeft: 0 }}>
                                    {textoAmpliado}
                                </Typography>
                            </Grid>
                        </Grid>
                        :
                        <Fragment />
                    }
                </Grid>
            </Box>
            {
                (() => {
                    if (parametros.seSalta) {
                        return <Redirect to={props.salto} />
                    }
                })()
            }
        </MuiThemeProvider >
    )
}
export default withTranslation()(BotonMercado)